<!--
 * @Description: 账户信息
 * @Author: luocheng
 * @Date: 2022-03-28 17:41:15
 * @LastEditors: 冉桂精 156189868@qq.com
 * @LastEditTime: 2024-11-06 17:16:11
-->
<template>
	<div class="account-info" v-if="userInfo">
		<!-- 头像 -->
		<section class="info-item avatar-item">
			<span class="label">{{ $t('components.UserInfo.300893-0') }}</span>
			<div class="info-box">
				<img :src="userInfo.avatar || defaultAvatar" alt="" class="avatar" />
			</div>
			<div class="actions">
				<a href="javascript:;" class="modify-avatar"
					>{{ $t('components.UserInfo.300893-1') }}
					<!-- 头像 -->
					<FileUpload
						ref="modifyAvatar"
						:files="preloadAvatar"
						:multiple="false"
						:fileTypeList="['bmp', 'jpg', 'png', 'gif', 'jpeg', 'cdr', 'psd']"
						@extra-files="onFileUpload"
						:fileAccept="'image/*'"
					></FileUpload>
				</a>
			</div>
		</section>
		<!-- 用户名 -->
		<section class="info-item name-item">
			<span class="label">{{ $t('components.AccountInfo.233336-0') }}</span>
			<div class="info-box">
				<el-input
					v-model="modifyName"
					:placeholder="$t('components.AccountInfo.233336-1')"
					clearable
					v-if="isModifyName"
				></el-input>
				<p class="text" v-else>{{ userInfo.name || '' }}</p>
			</div>
			<div class="actions">
				<template v-if="isModifyName">
					<a href="javascript:;" class="cancel" @click="onCancelName">{{ $t('components.AccountInfo.233336-2') }}</a>
					<a href="javascript:;" @click="onSaveName">{{ $t('components.AccountInfo.233336-3') }}</a>
				</template>
				<a href="javascript:;" @click="toModifyName" v-else>{{ $t('components.AccountInfo.233336-4') }}</a>
			</div>
		</section>
		<!-- 密码 -->
		<section class="info-item">
			<span class="label">{{ $t('components.AccountInfo.233336-5') }}</span>
			<div class="info-box">
				<p class="text password">········</p>
			</div>
			<div class="actions">
				<a href="javascript:;" @click="showForgetPass = true">{{ $t('components.AccountInfo.233336-6') }}</a>
				<a href="javascript:;" @click="showModifyPassword = true">{{ $t('components.AccountInfo.233336-7') }}</a>
			</div>
		</section>
		<!-- 手机号 -->
		<section class="info-item name-item">
			<span class="label">{{ $t('components.AccountInfo.233336-8') }}</span>
			<div class="info-box">
				<el-input
					v-model="modifyPhone"
					:placeholder="$t('components.AccountInfo.233336-1')"
					clearable
					v-if="isModifyPhone"
				></el-input>
				<p class="text" v-else>{{ replacePhone(userInfo.phone) }}</p>
			</div>
			<div class="actions">
				<template v-if="isModifyPhone">
					<a href="javascript:;" class="cancel" @click="onCancelPhone">{{ $t('components.AccountInfo.233336-2') }}</a>
					<a href="javascript:;" @click="onSavePhone">{{ $t('components.AccountInfo.233336-3') }}</a>
				</template>
				<a href="javascript:;" v-else @click="toModifyPhone">{{ $t('components.AccountInfo.233336-7') }}</a>
			</div>
		</section>
		<!-- 邮箱 -->
		<section class="info-item name-item">
			<span class="label">{{ $t('components.AccountInfo.761762-0') }}</span>
			<div class="info-box">
				<el-input
					v-model="modifyEmail"
					:placeholder="$t('components.AccountInfo.761762-1')"
					clearable
					v-if="isModifyEmail"
				></el-input>
				<p class="text" v-else>{{ replacePhone(userInfo.email) || '-' }}</p>
			</div>
			<div class="actions">
				<template v-if="isModifyEmail">
					<a href="javascript:;" class="cancel" @click="onCancelEmail">{{ $t('components.AccountInfo.761762-2') }}</a>
					<a href="javascript:;" @click="onSaveEmail">{{ $t('components.AccountInfo.761762-3') }}</a>
				</template>
				<a href="javascript:;" v-else @click="toModifyEmail">{{ $t('components.AccountInfo.761762-4') }}</a>
			</div>
		</section>
		<section class="info-item border-item">
			<span class="label">{{ $t('components.AccountInfo.233336-9') }}</span>
			<div class="info-box">
				<p class="text">
					{{
						userInfo.corp_wechat_id ? $t('components.AccountInfo.233336-10') : $t('components.AccountInfo.233336-11')
					}}
				</p>
			</div>
			<div class="actions">
				<a href="javascript:;" v-if="userInfo.corp_wechat_id" @click="onRelieve">{{
					$t('components.AccountInfo.233336-12')
				}}</a>
				<a href="javascript:;" v-else @click="onBind">{{ $t('components.AccountInfo.233336-13') }}</a>
			</div>
		</section>
		<!-- 返回登录页 -->
		<!-- <section class="back-login" v-if="isUpdatePwd">
      <el-button type="primary" @click="onQuit">返回登录页</el-button>
    </section> -->
		<!-- 修改密码 -->
		<!-- 修改密码蒙层不知道为什么z-index会压住所有区域? 先把蒙层关闭 -->
		<el-dialog
			:title="$t('components.AccountInfo.233336-14')"
			:visible.sync="showModifyPassword"
			width="400px"
			destroy-on-close
			:modal="false"
			:before-close="onClose"
			:close-on-click-modal="false"
			:close-on-press-escape="false"
			:show-close="false"
		>
			<p class="tips">{{ $t('components.AccountInfo.233336-15') }}</p>
			<el-form ref="modifyPasswordForm" :model="modifyPasswordForm" :rules="rules">
				<el-form-item :label="$t('components.AccountInfo.233336-16')" prop="password">
					<el-input
						v-model="modifyPasswordForm.password"
						:placeholder="$t('components.AccountInfo.233336-17')"
						show-password
						clearable
					></el-input>
				</el-form-item>
				<el-form-item :label="$t('components.AccountInfo.233336-18')" prop="newPassword">
					<el-input
						v-model="modifyPasswordForm.newPassword"
						:placeholder="$t('components.AccountInfo.233336-19')"
						show-password
						clearable
					></el-input>
				</el-form-item>
				<el-form-item :label="$t('components.AccountInfo.233336-20')" prop="confirmPassword">
					<el-input
						v-model="modifyPasswordForm.confirmPassword"
						:placeholder="$t('components.AccountInfo.233336-21')"
						show-password
						clearable
					></el-input>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="onClose(null)" v-if="!isUpdatePwd">{{ $t('components.AccountInfo.233336-22') }}</el-button>
				<el-button type="warning" @click="onQuit" v-else>{{ $t('components.AccountInfo.233336-23') }}</el-button>
				<el-button type="primary" @click="onModifyPassword">{{ $t('components.AccountInfo.233336-24') }}</el-button>
			</div>
		</el-dialog>
		<el-dialog
			:visible.sync="showForgetPass"
			:modal="false"
			:close-on-click-modal="false"
			:close-on-press-escape="false"
			width="400px"
			:title="$t('components.AccountInfo.233336-6')"
		>
			<ForgetPass :userInfo="userInfo" />
		</el-dialog>
	</div>
</template>

<script>
import FileUpload from '@/components/fileUpload/newFiles/index';
import ForgetPass from './forgetPass.vue';
import { dataInterface } from '@/apis/data';
import eventBus from '@/plugins/eventBus';
import { setToken } from '@/utils/tools';
import { mapState } from 'vuex';

export default {
	name: 'AccountInfo',
	components: {
		FileUpload,
		ForgetPass
	},
	data() {
		/* eslint-disable */
		const checkNewPassword = (rule, value, callback) => {
			// const reg = /^(?=.*[0-9\!@#\$%\^&\*])(?=.*[a-zA-Z]).{8,16}$/; // 数字，字母
			const reg = /^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[!@#$\%\^\&\*\(\)])[0-9a-zA-Z!@#$\%\^\&\*\(\)]{8,16}/;
			if (!value) {
				callback(new Error(this.$t('components.forgetPass.325653-6')));
			} else if (!reg.test(value)) {
				callback(new Error(this.$t('components.forgetPass.325653-7')));
			} else {
				callback();
			}
		};
		const checkConfirmPassword = (rule, value, callback) => {
			if (!value) {
				callback(new Error(this.$t('components.forgetPass.325653-8')));
			} else if (value !== this.modifyPasswordForm.newPassword) {
				callback(new Error(this.$t('components.forgetPass.325653-9')));
			} else {
				callback();
			}
		};
		return {
			userInfo: null,
			defaultAvatar: require('@/assets/images/default-avatar.png'),
			preloadAvatar: [],
			// 修改密码
			showModifyPassword: false,
			showForgetPass: false,
			modifyPasswordForm: {
				password: '',
				newPassword: '',
				confirmPassword: ''
			},
			rules: {
				password: [{ required: true, message: this.$t('components.AccountInfo.233336-17'), trigger: 'blur' }],
				newPassword: [
					{ required: true, message: this.$t('components.AccountInfo.233336-19'), trigger: 'blur' },
					{ validator: checkNewPassword, trigger: 'blur' }
				],
				confirmPassword: [
					{ required: true, message: this.$t('components.AccountInfo.233336-19'), trigger: 'blur' },
					{ validator: checkConfirmPassword, trigger: 'blur' }
				]
			},
			// 用户名
			isModifyName: false,
			modifyName: '',
			// 手机号
			isModifyPhone: false,
			modifyPhone: '',
			// 是否强制修改密码
			isUpdatePwd: true,
			//邮箱
			isModifyEmail: false,
			modifyEmail: ''
		};
	},
	computed: {
		...mapState(['systemConfig'])
	},
	created() {
		this.userInfo = this.$GetUserInfo();
		this.isUpdatePwd = localStorage.getItem('updatePwd') == '0' && this.systemConfig?.force_password;
		if (this.isUpdatePwd) {
			this.showModifyPassword = true;
		}
	},
	methods: {
		/**
		 * @desc: 上传头像图片
		 */
		onFileUpload(data) {
			if (!data || !data.length) return;
			this.$confirm(this.$t('components.AccountInfo.233336-28'))
				.then(() => {
					const file = data[data.length - 1];
					this.modifyInfo({
						avatar: file?.url
					});
					this.preloadAvatar = [];
				})
				.catch(() => {
					this.preloadAvatar = [];
				});
		},
		/**
		 * @desc: 前往编辑名称
		 */
		toModifyName() {
			this.modifyName = this.userInfo.name || '';
			this.isModifyName = true;
		},
		/**
		 * @desc: 编辑名称
		 */
		onSaveName() {
			this.modifyInfo({
				name: this.modifyName
			});
		},
		/**
		 * @desc: 取消编辑名称
		 */
		onCancelName() {
			this.isModifyName = false;
			this.modifyName = '';
		},
		/**
		 * @desc: 提交修改密码
		 */
		onModifyPassword() {
			this.$refs.modifyPasswordForm.validate((valid) => {
				if (!valid) return false;
				const loading = this.$loading();
				dataInterface(
					{
						old_password: this.modifyPasswordForm.password,
						password: this.modifyPasswordForm.newPassword,
						password_confirmation: this.modifyPasswordForm.confirmPassword
					},
					'api/user/changeNewPassword'
				)
					.then((res) => {
						loading.close();
						if (res.status === 200 && res.data.code === 200) {
							this.showModifyPassword = false;
							this.$message.success(this.$t('components.AccountInfo.233336-29'));
							this.$confirm(this.$t('components.AccountInfo.233336-30'), this.$t('components.AccountInfo.233336-31'), {
								confirmButtonText: this.$t('components.AccountInfo.233336-32'),
								cancelButtonText: this.$t('components.AccountInfo.761762-2')
							})
								.then(() => {
									this.onQuit();
								})
								.catch(() => {});
							return;
						} else {
							this.$message.error(this.$t('components.OperationFile.193459-12'));
						}
					})
					.catch((err) => {
						console.log(err);
						loading.close();
						this.$message.error(this.$t('components.OperationFile.193459-12'));
					});
			});
		},
		/**
		 * @desc: 退出登录
		 */
		onQuit() {
			// 移除路由文件重新获取一次
			setToken('');
			sessionStorage.clear();
			localStorage.clear();
			eventBus.$emit('updateRoute');
			// 刷新数据
			sessionStorage.removeItem('navHistoryList');
			this.$store.commit('setNavHistory', []);
			this.$store.commit('setTargetGlobalTab', '');
			this.$router.replace({
				path: '/login'
			});
			console.log('移除路由文件重新获取一次');
		},
		/**
		 * @desc: 手机号
		 */
		toModifyPhone() {
			this.isModifyPhone = true;
			this.modifyPhone = this.userInfo.phone || '';
		},
		/**
		 * @desc: 取消编辑手机号
		 */
		onCancelPhone() {
			this.modifyPhone = '';
			this.isModifyPhone = '';
		},
		/**
		 * @desc: 保存手机号
		 */
		onSavePhone() {
			const phoneReg =
				/^(((13[0-9]{1})|(14[0-9]{1})|(15[0-9]{1})|(16[0-9]{1})|(17[0-9]{1})|(19[0-9]{1})|(18[0-9]{1}))+\d{8})$/;
			if (!phoneReg.test(this.modifyPhone)) {
				this.$message.warning(this.$t('components.AccountInfo.233336-35'));
				return;
			}
			this.modifyInfo({
				phone: this.modifyPhone
			});
		},
		/**
		 * @desc: 邮箱
		 */
		toModifyEmail() {
			this.isModifyEmail = true;
			this.modifyEmail = this.userInfo.email || '';
		},
		onCancelEmail() {
			this.modifyEmail = '';
			this.isModifyEmail = false;
		},
		onSaveEmail() {
			const mailReg = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
			if (!this.modifyEmail) {
				return callback(this.$t('components.AccountInfo.233336-46'));
			}
			if (!mailReg.test(this.modifyEmail)) {
				this.$message.warning(this.$t('components.AccountInfo.233336-47'));
				return;
			}
			this.modifyInfo({
				email: this.modifyEmail
			});
		},
		/**
		 * @desc: 解除绑定
		 */
		onRelieve() {
			this.$confirm(this.$t('components.AccountInfo.233336-36'))
				.then(() => {
					const loading = this.$loading();
					let archiType = '';
					const typeString = this.$GetTargetArchi('archiType');
					if (typeString === 'project') {
						archiType = 2;
					} else if (typeString === 'company') {
						archiType = 1;
					} else {
						loading.close();
						this.$message.error(this.$t('components.OperationFile.193459-12'));
						return;
					}
					dataInterface(
						{
							archiID: this.$GetTargetArchi('archiId'),
							archiType,
							user_id: this.userInfo.id
						},
						'api/wechat/deleteSync',
						'DELETE'
					)
						.then((res) => {
							loading.close();
							if (res.status === 200) {
								this.$message.success(this.$t('components.AccountInfo.233336-38'));
								this.refreshUserInfo();
							} else {
								this.$message.error(this.$t('components.OperationFile.193459-12'));
							}
						})
						.catch((err) => {
							console.log(err, '2222');
							loading.close();
							this.$message.error(this.$t('components.OperationFile.193459-12'));
						});
				})
				.catch(() => {});
		},
		/**
		 * @desc: 绑定企业微信
		 */
		onBind() {
			const loading = this.$loading();
			let archiType = '';
			const typeString = this.$GetTargetArchi('archiType');
			if (typeString === 'project') {
				archiType = 2;
			} else if (typeString === 'company') {
				archiType = 1;
			} else {
				loading.close();
				this.$message.error(this.$t('components.AccountInfo.233336-37'));
				return;
			}
			dataInterface(
				{
					archiID: this.$GetTargetArchi('archiId'),
					archiType,
					user_id: this.userInfo.id
				},
				'api/wechat/syncOne',
				'POST'
			)
				.then((res) => {
					loading.close();
					if (res.status === 200) {
						this.$message.success(this.$t('components.AccountInfo.233336-39'));
						this.refreshUserInfo();
					} else {
						this.$message.error(this.$t('components.AccountInfo.233336-37'));
					}
				})
				.catch((err) => {
					console.log(err, '2222');
					loading.close();
					this.$message.error(this.$t('components.AccountInfo.233336-37'));
				});
		},
		/**
		 * @desc: 提交编辑
		 * @param {Object} params 参数
		 */
		modifyInfo(params) {
			const loading = this.$loading();
			dataInterface({
				__method_name__: 'updateData',
				object_uuid: '844a47c6-6380-46dd-bc4a-d73b6245175f',
				view_uuid: '1020e62a-c119-4bcc-882a-eb0bcf760a86',
				data_id: this.userInfo.id,
				...params
			})
				.then((res) => {
					if (res.status === 200) {
						this.$message.success(this.$t('components.AccountInfo.233336-40'));
						this.refreshUserInfo();
						this.onCancelName();
						this.onCancelPhone();
						this.onCancelEmail();
					}
				})
				.catch((err) => {
					console.log(err);
					loading.close();
					this.$message.error(this.$t('components.OperationFile.193459-12'));
				});
		},
		/**
		 * @desc: 刷新用户信息
		 */
		refreshUserInfo() {
			const loading = this.$loading();
			dataInterface({
				__method_name__: 'dataInfo',
				object_uuid: '844a47c6-6380-46dd-bc4a-d73b6245175f',
				view_uuid: '1020e62a-c119-4bcc-882a-eb0bcf760a86',
				data_id: this.userInfo.id,
				transcode: 0
			})
				.then((res) => {
					if (!res.status) return;
					const { avatar, name, phone, email } = res?.data?.data || {};
					localStorage.setItem(
						'userInfo',
						JSON.stringify({
							...this.userInfo,
							avatar,
							name,
							phone,
							email
						})
					);
					this.userInfo = this.$GetUserInfo();
					eventBus.$emit('updateUserInfo');
					loading.close();
				})
				.catch((err) => {
					console.log(err);
					loading.close();
				});
		},
		/**
		 * @desc: 手机号处理
		 */
		replacePhone(phone) {
			return phone?.toString()?.replace(/(\d{3})\d{4}(\d{4})/, '$1****$2');
		},
		/**
		 * @desc: 取消修改密码
		 */
		onClose(done) {
			this.modifyPasswordForm = {
				password: '',
				newPassword: '',
				confirmPassword: ''
			};
			if (done) {
				done();
			}
			this.showModifyPassword = false;
		}
	}
};
</script>

<style lang="less" scoped>
@itemHeight: 22px;
.account-info {
	width: 100%;
	height: auto;
	.info-item {
		min-height: @itemHeight;
		margin-top: 24px;
		padding: 0 40px;
		width: 100%;
		display: flex;
		box-sizing: border-box;
		&.border-item {
			border-bottom: 1px solid #e9ebf0;
			padding-bottom: 24px;
		}
		&.avatar-item {
			.label {
				line-height: 56px;
			}
			.actions a {
				display: block;
				height: 56px;
				line-height: 62px;
			}
		}
		&.name-item {
			.label {
				line-height: 32px;
			}
			.info-box .text {
				line-height: 32px;
			}
			.actions a {
				line-height: 32px;
			}
		}
		.label {
			font-size: 14px;
			font-family: PingFangSC-Regular, PingFang SC;
			font-weight: 400;
			color: #4d5358;
			line-height: 22px;
			width: 58px;
		}
		.info-box {
			box-sizing: border-box;
			padding: 0 24px;
			flex: 1;
			min-height: 22px;
			.text {
				font-size: 14px;
				font-family: PingFangSC-Regular, PingFang SC;
				font-weight: 400;
				color: #121619;
				line-height: 22px;
				&.password {
					font-weight: bold;
					font-size: 26px;
					letter-spacing: -4px;
				}
			}
			.avatar {
				height: 56px;
				width: 56px;
				border-radius: 8px;
			}
		}
		.actions {
			width: auto;
			min-height: 22px;
			box-sizing: border-box;
			a {
				font-size: 12px;
				font-family: PingFangSC-Medium, PingFang SC;
				font-weight: 500;
				color: var(--themeColor);
				line-height: 22px;
				&.cancel {
					color: #4d5358;
				}
				&.modify-avatar {
					position: relative;
					overflow: hidden;
					.file-upload {
						position: absolute;
						height: 100%;
						width: 100%;
						top: 0;
						left: 0;
						overflow: hidden;
						padding: 0;
						opacity: 0;
					}
				}
			}
			a + a {
				margin-left: 10px;
			}
		}
	}
	.tips {
		color: @minorColor;
		line-height: 16px;
	}
	.back-login {
		margin: 50px auto;
		text-align: center;
	}
}
</style>
